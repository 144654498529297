import React from "react";
import MaxWidthWrapper from "../common/MaxWidthWrapper";
import SecondaryWidthWrapper from "../common/SecondaryWidthWrapper";
import ReactMarkdown from "react-markdown";
import "../../styles/jobPost/richeText.css";
import rehypeRaw from "rehype-raw";
import gfm from "remark-gfm";

interface jobRequirementProps {
  richText?: any;
  coverImage?: string;
}

const JobRequirements: React.FC<jobRequirementProps> = ({
  richText,
  coverImage,
}) => {
  return (
    <div>
      <MaxWidthWrapper>
        <SecondaryWidthWrapper>
          <div
            className={
              "flex flex-col-reverse items-center py-[80px] lg:flex lg:flex-row lg:justify-between lg:py-[150px]"
            }
          >
            <div className={"content-center"}>
              <div className={"prose"}>
                <ReactMarkdown
                  remarkPlugins={[gfm]}
                  rehypePlugins={[rehypeRaw]}
                  transformImageUri={(uri: string) =>
                    uri.startsWith("http")
                      ? uri
                      : `${process.env.GATSBY_BACKEND_URL}${uri}`
                  }
                >
                  {richText}
                </ReactMarkdown>
              </div>
            </div>
            <div className={""}>
              <img
                className={"h-full w-full"}
                src={coverImage}
                alt={"Requirement cover image"}
              />
            </div>
          </div>
        </SecondaryWidthWrapper>
      </MaxWidthWrapper>
    </div>
  );
};

export default JobRequirements;
