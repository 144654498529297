import React from "react";

interface JobBenefitsItemProps {
  index: number;
  icon: string;
  text: string;
  allItemLength: number;
  altText: string;
}

const JobBenefitsItems: React.FC<JobBenefitsItemProps> = ({
  index,
  icon,
  text,
  allItemLength,
  altText,
}) => {
  return (
    <div
      className={
        "h-[250px] w-[300px] lg:flex lg:items-center lg:bg-white xl:h-[260px] xl:w-[300px] xl:bg-white"
      }
    >
      <div
        className={
          "relative flex flex h-[250px] w-full flex-col items-center justify-center gap-y-[18px]"
        }
      >
        <div
          className={
            "flex w-[200px] flex-col items-center justify-center gap-y-[24px]"
          }
        >
          <div>
            <img className={"h-[50px] w-[50px]"} src={icon} alt={altText} />
          </div>
          <p className={"text-center text-[14px] font-medium text-[#111111]"}>
            {text}
          </p>
        </div>
      </div>
      <div
        className={
          "absolute w-[300px] bg-primary p-0.5 lg:hidden lg:w-[331px] xl:w-[300px]"
        }
      ></div>
      {allItemLength !== index && (
        <div
          className={
            "absolute hidden h-[180px] border-r-4 border-primary lg:block lg:w-[300px]"
          }
        ></div>
      )}
    </div>
  );
};

export default JobBenefitsItems;
