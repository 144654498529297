import React from "react";

interface jobPostProps {
  index: number;
  title: string;
  value: string;
  allItemLength: number;
}
const JobPostItems: React.FC<jobPostProps> = ({
  index,
  title,
  value,
  allItemLength,
}) => {
  return (
    <div
      className={
        "h-[250px] w-[300px] lg:flex lg:items-center lg:bg-white xl:h-[260px] xl:w-[300px] xl:bg-white"
      }
    >
      <div
        className={
          "relative flex flex h-[250px] w-full flex-col items-center justify-center gap-y-[18px]"
        }
      >
        <div
          className={
            "flex w-[200px] flex-col items-center justify-center gap-y-[24px] text-[#111111]"
          }
        >
          <div className={"text-primary"}>
            <p className={"text-center text-[18px] font-medium"}>{title}</p>
          </div>
          <p className={"text-center text-[14px] "}>{value}</p>
        </div>
      </div>
      <div
        className={
          "absolute w-[300px] bg-primary p-0.5 lg:hidden lg:w-[331px] xl:w-[300px]"
        }
      ></div>
      {allItemLength !== index && (
        <div
          className={
            "absolute hidden h-[180px] border-r-4 border-primary lg:block lg:w-[230px] xl:w-[300px]"
          }
        ></div>
      )}
    </div>
  );
};

export default JobPostItems;
