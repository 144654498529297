import React from "react";
import NavbarType from "../types/Navbar.type";
import Layout from "../components/layout";
import classNames from "classnames";
// @ts-ignore
import jobDetailsCoverImage from "../../static/img/job-details-cover-image.svg";
import HeroPoster from "../components/heroPoster/heroPoster";
import JobSmallDetail from "../components/jobPost/jobSmallDetail";
import JobBenefits from "../components/jobPost/jobBenefits";
// @ts-ignore
import benefitIcon1 from "../../static/img/benefitsIcons/working-icon.svg";
// @ts-ignore
import benefitIcon2 from "../../static/img/benefitsIcons/working-icon2.svg";
// @ts-ignore
import benefitIcon3 from "../../static/img/benefitsIcons/working-icon3.svg";
import JobRequirements from "../components/jobPost/jobRequirements";
// @ts-ignore
import requirementCoverImage from "../../static/img/requirements-cover-img.svg";
import moment from "moment";
import SeoDataHandler from "../helper/handler/seoData.handler";
import { SEO } from "../components/seo";

interface PageContextProps {
  common: {
    navbarData: NavbarType;
    footerData: any;
  };
  availableJobData: any;
}

interface JobPostProps {
  pageContext: PageContextProps;
}

const JobPost: React.FC<JobPostProps> = ({ pageContext }) => {
  const PageData = {
    data: [
      {
        id: 2,
        attributes: {
          name: "",
          slug: "",
          order: 1,
          url: "",
          coverSection: {
            id: 1,
            subText: {
              id: 1,
              primaryText: "About The Profile",
              secondaryText: "",
            },
            description: {
              id: 2,
              primaryText:
                "At Propelius Technologies we don't hire Employees we build friendships. We are looking for friends to join us as we explore the ever-changing landscape of technology.",
              secondaryText: "",
            },
            title: {
              id: 2,
              primaryText: `${pageContext.availableJobData.jobTitle}`,
              secondaryText: "",
            },
            button: {
              id: "1",
              text: "Apply Now",
              url: "",
            },
          },
        },
      },
    ],
    meta: {
      pagination: {
        page: 0,
        pageSize: 0,
        pageCount: 0,
        total: 0,
      },
    },
  };

  const date = moment(
    pageContext?.availableJobData?.publishedAt,
    "'YYYY/MM/DD'"
  );
  const year = date.year();
  const month = date.format("MMMM");
  const day = date.day();

  const jobSmallDetail = [
    {
      key: "Job Location",
      value: `${
        pageContext?.availableJobData?.jobLocation
          ? pageContext?.availableJobData?.jobLocation
          : "--"
      }`,
    },
    {
      key: "Type of Job",
      value: `${
        pageContext?.availableJobData?.jobType
          ? pageContext?.availableJobData?.jobType
          : "--"
      }`,
    },
    {
      key: "Published Date",
      value: `${
        pageContext?.availableJobData?.publishedAt
          ? `${month} ${day} ${year}`
          : "--"
      }`,
    },
    {
      key: "Experience",
      value: `${
        pageContext?.availableJobData?.jobExperienceRequired
          ? pageContext?.availableJobData?.jobExperienceRequired
          : "--"
      }`,
    },
  ];
  const jobBenefitDetails = [
    {
      icon: benefitIcon1,
      text: "5 Days Working",
      altText: "#",
    },
    {
      icon: benefitIcon2,
      text: "Office Outings",
      altText: "#",
    },
    {
      icon: benefitIcon3,
      text: "Flexible Hours",
      altText: "#",
    },
  ];

  return (
    <Layout
      footerData={pageContext?.common?.footerData}
      navbarData={pageContext?.common?.navbarData}
    >
      <div className={classNames(" pb-[40px]")}>
        {/*<MaxWidthWrapper>*/}
        <HeroPoster
          // @ts-ignore
          pageData={PageData}
          coverUrl={jobDetailsCoverImage}
          contactForm={true}
        />
        <JobSmallDetail jobSmallDetails={jobSmallDetail} />
        <JobRequirements
          richText={pageContext?.availableJobData?.jobDescription}
          coverImage={requirementCoverImage}
        />
        <JobBenefits jobBenefitDetails={jobBenefitDetails} />
        {/*</MaxWidthWrapper>*/}
      </div>
    </Layout>
  );
};
export default JobPost;

export const Head: React.FC<any> = ({ pageContext }) => {
  const seoData = SeoDataHandler(pageContext?.jobPostPageData?.pageData);
  return (
    <SEO
      title={seoData.title}
      description={seoData.description}
      keywords={seoData.keywords}
      image={seoData.image}
      pathname={seoData.pagePath}
    />
  );
};
