import React from "react";
import MaxWidthWrapper from "../common/MaxWidthWrapper";
import JobBenefitsItems from "./jobBenefitsItems";

interface JobBenefitProps {
  jobBenefitDetails: { icon: string; text: string; altText: string }[];
}

const JobBenefits: React.FC<JobBenefitProps> = ({ jobBenefitDetails }) => {
  return (
    <div>
      <MaxWidthWrapper>
        <div>
          <div>
            <h2 className={"text-center text-[52px] font-bold text-[#111111]"}>
              Benefits
            </h2>
          </div>
          <div
            className={
              "flex flex-col items-center justify-center md:grid md:grid-cols-2 md:place-items-center md:gap-x-4 lg:mt-[50px] lg:flex lg:flex-row lg:gap-0 lg:drop-shadow-lg"
            }
          >
            {jobBenefitDetails.map((item, index) => (
              <JobBenefitsItems
                index={index}
                icon={item.icon}
                text={item.text}
                allItemLength={jobBenefitDetails.length - 1}
                altText={item.altText}
              />
            ))}
          </div>
        </div>
      </MaxWidthWrapper>
    </div>
  );
};

export default JobBenefits;
