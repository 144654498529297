import PageDataType from "../../types/page.type";

const homePosterDataHandler = (
  coverUrl: string | undefined,
  pageData: PageDataType | undefined
) => {
  return {
    imageData: {
      url: coverUrl,
      altText:
        pageData?.data?.[0]?.attributes?.coverSection?.imageData?.altText,
      forwardUrl: pageData?.data?.[0]?.attributes?.coverSection?.imageData?.url,
    },
    subText: {
      primaryText:
        pageData?.data?.[0]?.attributes?.coverSection?.subText?.primaryText ||
        "",
      secondaryText:
        pageData?.data?.[0]?.attributes?.coverSection?.subText?.secondaryText ||
        "",
    },
    titleText: {
      primaryText:
        pageData?.data?.[0]?.attributes?.coverSection?.title?.primaryText || "",
      secondaryText:
        pageData?.data?.[0]?.attributes?.coverSection?.title?.secondaryText ||
        "",
    },
    descriptionText: {
      primaryText:
        pageData?.data?.[0]?.attributes?.coverSection?.description
          ?.primaryText || "",
      secondaryText:
        pageData?.data?.[0]?.attributes?.coverSection?.description
          ?.secondaryText || "",
    },
    buttonData: {
      text: pageData?.data?.[0]?.attributes?.coverSection?.button?.text || "",
      url: pageData?.data?.[0]?.attributes?.coverSection?.button?.url || "",
    },
    gradient: true,
  };
};
export default homePosterDataHandler;
