import React from "react";
import classNames from "classnames";
import MaxWidthWrapper from "../../components/common/MaxWidthWrapper";
import PageDataType from "../../types/page.type";
import homePosterDataHandler from "../../helper/handler/homePosterData.handler";
import SecondaryWidthWrapper from "../common/SecondaryWidthWrapper";

export interface HeroPosterProps {
  contactForm?: boolean;
  pageData: PageDataType | undefined;
  coverUrl: string | undefined;
}

const HeroPoster: React.FC<HeroPosterProps> = ({
  pageData,
  coverUrl,
  contactForm,
}) => {
  const updatedPageData = homePosterDataHandler(coverUrl, pageData);

  return (
    <div
      className={classNames(
        "flex items-center justify-center bg-gradient-to-b  to-hero-gradient-end pt-[30px] pb-[30px] xl:pt-[50px] xl:pb-[93px]",
        { "from-hero-gradient-start": true, "from-white": false }
      )}
    >
      <MaxWidthWrapper>
        {/*Main Div Container*/}
        <SecondaryWidthWrapper>
          <div
            className={
              "flex flex-col-reverse items-center justify-center md:flex  md:flex-col-reverse lg:flex-row"
            }
          >
            {/*Title Div Container*/}
            <div className={"mt-[24px] flex flex-1 flex-col md:mt-0"}>
              <div className={"flex flex-col justify-between"}>
                <div>
                  {updatedPageData?.subText?.primaryText !== "" && (
                    <div className={"mb-[8px] md:mt-[90px]"}>
                      <p
                        className={
                          "relative z-[2] text-center md:text-center lg:text-left"
                        }
                      >
                        <span
                          className={
                            "text-center text-[14px] font-[300] text-[#424242] md:text-center md:text-[20px] md:font-[400] lg:text-left"
                          }
                        >
                          {`${updatedPageData?.subText?.primaryText}\xa0`}
                        </span>
                        <span
                          className={
                            "text-center text-[12px] font-[300] text-primary md:text-left md:text-[20px] md:font-[400]"
                          }
                        >
                          {updatedPageData?.subText?.secondaryText}
                        </span>
                      </p>
                    </div>
                  )}
                  <div
                    className={
                      "flex flex-col items-center gap-[8px] lg:items-start"
                    }
                  >
                    <h1
                      className={
                        "relative z-[2] text-center text-[20px] font-[600] text-[#37474F] sm:text-[28px] md:text-center md:text-[28px] lg:text-left lg:text-[38px]"
                      }
                    >
                      {updatedPageData?.titleText?.primaryText}
                      <br />
                      <span className={"text-primary"}>
                        {updatedPageData?.titleText?.secondaryText}
                      </span>
                    </h1>
                    {/*<h5 className={"text-[25px] text-[#37474F]"}>{subtitle}</h5>*/}
                    <h5
                      className={
                        "relative z-[2] w-4/5 text-center text-[15px] text-[#37474F] md:w-full md:text-[25px] lg:text-left"
                      }
                    >
                      {updatedPageData?.descriptionText?.primaryText}&nbsp;
                      <span className={"text-primary"}>
                        {updatedPageData?.descriptionText?.secondaryText}
                      </span>
                    </h5>
                  </div>
                </div>
                {updatedPageData?.buttonData?.text !== "" && (
                  <div
                    className={
                      "relative z-[2] flex justify-center py-5 md:justify-center lg:justify-start"
                    }
                  >
                    <div
                      className={
                        "min-w-[140px] rounded-[7px] bg-[#66C893] px-[24px] py-[12px] text-center md:min-w-[300px]"
                      }
                    >
                      <p
                        className={
                          "text-[14px] font-[500] text-[#FFFFFF] md:text-[23px]"
                        }
                      >
                        <a
                          href={
                            contactForm
                              ? "/contact-propelius-technologies"
                              : typeof window !== "undefined" &&
                                window.location.pathname === "/"
                              ? "#contact-us"
                              : "#findRole"
                          }
                        >
                          {updatedPageData?.buttonData?.text}
                        </a>
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/*Image Div Container*/}
            <div className={"flex flex-[1.4] justify-center"}>
              <div
                className={
                  "relative z-[2] h-[350px] w-[90%] md:h-[500px] md:w-[95%]"
                }
              >
                <img
                  className={"bg-cover"}
                  src={
                    updatedPageData?.imageData?.url
                      ? updatedPageData?.imageData.url
                      : ""
                  }
                  alt={updatedPageData?.imageData?.altText}
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                />
              </div>
            </div>
          </div>
        </SecondaryWidthWrapper>
      </MaxWidthWrapper>
    </div>
  );
};

export default HeroPoster;
