import React from "react";
import MaxWidthWrapper from "../common/MaxWidthWrapper";
import SecondaryWidthWrapper from "../common/SecondaryWidthWrapper";
import JobPostItems from "./jobPostItems";

interface jobSmallDetail {
  jobSmallDetails: {
    key: string;
    value: string;
  }[];
}

const JobSmallDetail: React.FC<jobSmallDetail> = ({ jobSmallDetails }) => {
  return (
    <div>
      <MaxWidthWrapper>
        <SecondaryWidthWrapper>
          <div
            className={
              "flex flex-col items-center justify-center md:grid md:grid-cols-2 md:gap-x-4 lg:mt-[50px] lg:flex lg:flex-row lg:gap-0 lg:drop-shadow-lg"
            }
          >
            {jobSmallDetails.map((item, index) => (
              <JobPostItems
                index={index}
                title={item.key}
                value={item.value}
                allItemLength={jobSmallDetails.length - 1}
              />
            ))}
          </div>
        </SecondaryWidthWrapper>
      </MaxWidthWrapper>
    </div>
  );
};

export default JobSmallDetail;
